import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Modal from 'react-modal';
import styled from 'styled-components';

Modal.setAppElement('#root');

const StyledModalOverlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledModalContent = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
`;

const Container = styled.div`
  max-width: 600px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const StyledButton = styled.button`
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const StyledLabel = styled.label`
  margin: 10px 0 5px;
  display: block;
  text-align: left;
`;

const ClientInput = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const reportId = params.get('reportId');

  const [ssn, setSSN] = useState('');
  const [enteredPin, setEnteredPin] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [report, setReport] = useState(null);
  const [accessDenied, setAccessDenied] = useState(false);
  const [submissionComplete, setSubmissionComplete] = useState(false);

  useEffect(() => {
    const fetchReport = async () => {
      if (reportId) {
        const reportDoc = await getDoc(doc(db, 'reports', reportId));
        if (reportDoc.exists()) {
          setReport(reportDoc.data());
        } else {
          alert('Invalid report ID');
          navigate('/');
        }
      }
    };
    fetchReport();
  }, [reportId, navigate]);

  const handleChange = (e) => {
    let { value } = e.target;
    const cleanedValue = value.replace(/\D/g, '');
    const limitedValue = cleanedValue.slice(0, 9);
    const formattedValue = limitedValue
      .replace(/^(\d{3})/, '$1-')
      .replace(/(\d{3})-(\d{2})/, '$1-$2-')
      .replace(/(\d{3}-\d{2})-(\d{4}).*/, '$1-$2');
    setSSN(formattedValue);
  };

  const handleAccessPinChange = (e) => {
    setEnteredPin(e.target.value);
  };

  const handleValidatePin = () => {
    if (report.accessPinCode === enteredPin) {
      setIsModalOpen(false);
    } else {
      alert('Invalid access PIN');
    }
  };

  const handleSave = async () => {
    if (!reportId) {
      alert('Report ID is missing');
      return;
    }

    const reportRef = doc(db, 'reports', reportId);

    try {
      await updateDoc(reportRef, { ssn });
      setSubmissionComplete(true); // Set submission complete to true
    } catch (error) {
      console.error('Error updating document: ', error);
      alert('Error saving SSN');
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {}}
        overlayClassName="modal-overlay"
        className="modal-content"
        contentLabel="Access Pin Modal"
      >
        <StyledModalContent>
          <h2>Enter Access Pin</h2>
          <StyledInput
            type="text"
            value={enteredPin}
            onChange={handleAccessPinChange}
            placeholder="Enter PIN"
          />
          <StyledButton onClick={handleValidatePin}>Submit</StyledButton>
        </StyledModalContent>
      </Modal>

      {!isModalOpen && !accessDenied && report && !submissionComplete && (
        <PageWrapper>
          <Container>
            <h1>Client Verification</h1>
            <form>
              <div>
                <StyledLabel>Name:</StyledLabel>
                <StyledInput type="text" value={report.name || ''} readOnly />
              </div>
              <div>
                <StyledLabel>Email:</StyledLabel>
                <StyledInput type="text" value={report.email || ''} readOnly />
              </div>
              <div>
                <StyledLabel>Phone:</StyledLabel>
                <StyledInput type="text" value={report.phone || ''} readOnly />
              </div>
              <div>
                <StyledLabel>SSN:</StyledLabel>
                <StyledInput
                  type="text"
                  value={ssn}
                  onChange={handleChange}
                  placeholder="xxx-xx-xxxx"
                  maxLength={11}
                  id="ssn"
                  name="ssn"
                />
              </div>
              <StyledButton type="button" onClick={handleSave}>Submit</StyledButton>
            </form>
          </Container>
        </PageWrapper>
      )}

      {!isModalOpen && !accessDenied && submissionComplete && (
        <PageWrapper>
          <Container>
            <h1>Thank you for submitting</h1>
            <p className="client-thankyou">Once the agent has verified receipt, you may close this link.</p>
          </Container>
        </PageWrapper>
      )}

      {accessDenied && <div>Access Denied</div>}
    </div>
  );
}

export default ClientInput;

