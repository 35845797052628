// src/ReportModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { db, auth } from '../firebase';
import { collection, addDoc, Timestamp, getDoc, doc } from 'firebase/firestore';

Modal.setAppElement('#root'); // Adjust this according to your root element

const ReportModal = ({ isOpen, onClose, onReportCreated }) => {
  const [formData, setFormData] = useState({
    accessCode: '',
    accessPinCode: '',
    dob: '',
    duration: '',
    email: '',
    enrolled: false,
    enrolledAmount: '',
    federalLiability: '0',
    lead: false,
    name: '',
    notEnoughDebt: false,
    notes: '',
    phone: '',
    phoneSuffix: '',
    ssn: '',
    startTime: '',
    stateLiability: '0',
    transfer: '' // Initially empty
  });

  const [userDepartment, setUserDepartment] = useState('');

  useEffect(() => {
    const fetchUserDepartment = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const department = userDoc.data().department;
          setUserDepartment(department);
          setFormData((prevFormData) => ({
            ...prevFormData,
            transfer: department === 'tax' ? 'T-1' : '1' // Set default transfer value based on department
          }));
        }
      }
    };

    fetchUserDepartment();
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let updatedValue = value;

    if (name === 'phone') {
      // Format phone number to digits only
      updatedValue = value.replace(/\D/g, '').slice(0, 10); // Replace non-digits and limit to 10 characters
    }

    if (name === 'duration') {
      // Format duration input to HH:MM:SS
      updatedValue = formatDuration(value);
    }

    if (name === 'startTime') {
      // Format start time input to HH:MM AM/PM
      updatedValue = formatStartTime(value);
    }

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : updatedValue
    });
  };

  const formatDuration = (value) => {
    const cleanedValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    const formattedValue = cleanedValue.match(/.{1,2}/g)?.join(':') || ''; // Insert colons between each group of 2 digits
    return formattedValue;
  };

  const formatStartTime = (value) => {
    const cleanedValue = value.replace(/[^0-9apAP]/g, '').toUpperCase(); // Remove non-numeric and non-am/pm characters
    let timePart = cleanedValue.slice(0, 4); // First 4 characters are the time part
    let periodPart = cleanedValue.slice(4); // Remaining characters are the period part

    // Insert colon between the first two and second two digits
    if (timePart.length >= 3) {
      timePart = timePart.slice(0, 2) + ':' + timePart.slice(2, 4);
    }

    // Ensure only one set of AM/PM is allowed
    if (periodPart.includes('A') || periodPart.includes('P')) {
      if (periodPart.includes('A')) {
        periodPart = 'AM';
      } else if (periodPart.includes('P')) {
        periodPart = 'PM';
      }
    } else {
      periodPart = '';
    }

    return timePart + (periodPart ? ' ' + periodPart : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      const newReport = {
        ...formData,
        transfer: formData.transfer || '1', // Ensure transfer is set to "1" if not changed
        agentId: user.uid,
        createdAt: Timestamp.now()
      };
      try {
        await addDoc(collection(db, 'reports'), newReport);
        setFormData({ // Reset form data after successful submission
          accessCode: '',
          accessPinCode: '',
          dob: '',
          duration: '',
          email: '',
          enrolled: false,
          enrolledAmount: '',
          federalLiability: '0',
          lead: false,
          name: '',
          notEnoughDebt: false,
          notes: '',
          phone: '',
          phoneSuffix: '',
          ssn: '',
          startTime: '',
          stateLiability: '0',
          transfer: userDepartment === 'tax' ? 'T-1' : '1' // Reset transfer to default value
        });
        onReportCreated(); // Trigger the report creation handler
        onClose();
      } catch (error) {
        console.error('Error adding document: ', error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="ModalOverlay">
      <form onSubmit={handleSubmit} className="modal-form">
        <div>
          <label>Transfer</label>
          <select name="transfer" value={formData.transfer} onChange={handleChange}>
            {userDepartment === 'tax' ? (
              <>
                <option value="T-1">T-1</option>
                <option value="T-2">T-2</option>
                <option value="T-3">T-3</option>
                <option value="T-4">T-4</option>
                <option value="T-5">T-5</option>
                <option value="T-6">T-6</option>
                <option value="CPT">CPT</option>
              </>
            ) : (
              <>
                {[...Array(55).keys()].map(i => (
                  <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
                <option value="CPD">CPD</option>
                <option value="IAT">IAT</option>
                <option value="HUB">HUB</option>
                <option value="FB-1">FB-1</option>
                <option value="FB-2">FB-2</option>
                <option value="FB-3">FB-3</option>
                <option value="FB-4">FB-4</option>
                <option value="FB-5">FB-5</option>
                <option value="GA-1">GA-1</option>
                <option value="GA-2">GA-2</option>
                <option value="GA-3">GA-3</option>
                <option value="GA-4">GA-4</option>
                <option value="GA-5">GA-5</option>
                <option value="IG-1">IG-1</option>
                <option value="IG-2">IG-2</option>
                <option value="IG-3">IG-3</option>
                <option value="TK-1">TK-1</option>
                <option value="TK-2">TK-2</option>
                <option value="TK-3">TK-3</option>
                <option value="YT-1">YT-1</option>
                <option value="YT-2">YT-2</option>
                <option value="YT-3">YT-3</option>
              </>
            )}
          </select>
        </div>
        <div>
          <label>Access Code</label>
          <input name="accessCode" value={formData.accessCode} onChange={handleChange} />
        </div>
        <div>
          <label>Name</label>
          <input name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div>
          <label>Access Pin Code</label>
          <input name="accessPinCode" type="number" value={formData.accessPinCode} onChange={handleChange} />
        </div>
        <div>
          <label>Phone</label>
          <input name="phone" value={formData.phone} onChange={handleChange} />
        </div>
        <div>
          <label>Phone Suffix</label>
          <input name="phoneSuffix" value={formData.phoneSuffix} onChange={handleChange} />
        </div>
        <div>
          <label>Start Time</label>
          <input
            name="startTime"
            value={formData.startTime}
            onChange={handleChange}
            placeholder="HH:MM AM/PM"
          />
        </div>
        <div>
          <label>Date of Birth</label>
          <input name="dob" type="date" value={formData.dob} onChange={handleChange} />
        </div>
        <div>
          <label>Duration</label>
          <input
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            placeholder="HH:MM:SS"
          />
        </div>
        <div>
          <label>Enrolled</label>
          <input name="enrolled" type="checkbox" checked={formData.enrolled} onChange={handleChange} />
        </div>
        <div>
          <label>Notes</label>
          <textarea name="notes" value={formData.notes} onChange={handleChange} />
        </div>
        <div>
          <label>Lead</label>
          <input name="lead" type="checkbox" checked={formData.lead} onChange={handleChange} />
        </div>
        <div>
          <label>Enrolled Amount</label>
          <input name="enrolledAmount" value={formData.enrolledAmount} onChange={handleChange} />
        </div>
        <div>
          <label>Not Enough Debt</label>
          <input name="notEnoughDebt" type="checkbox" checked={formData.notEnoughDebt} onChange={handleChange} />
        </div>
        <div>
          <label>Email</label>
          <input name="email" type="email" value={formData.email} onChange={handleChange} />
        </div>
        <div>
          <label>SSN</label>
          <input name="ssn" value={formData.ssn} onChange={handleChange} />
        </div>

        {userDepartment !== 'debt' && (
          <>
            <div>
              <label>State Liability</label>
              <input name="stateLiability" value={formData.stateLiability} onChange={handleChange} />
            </div>
            <div>
              <label>Federal Liability</label>
              <input name="federalLiability" value={formData.federalLiability} onChange={handleChange} />
            </div>
          </>
        )}

        <button type="submit">Create Report</button>
        <button type="button" onClick={onClose}>Close</button>
      </form>
    </Modal>
  );
};

export default ReportModal;