// src/AgentPage.js
import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, query, where, getDocs, Timestamp, updateDoc, doc } from 'firebase/firestore';
import ReportModal from './ReportModal';
import EditModal from './EditModal';

function AgentPage() {
  const [reports, setReports] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [department, setDepartment] = useState('');

  const getDurationColor = (duration) => {
    const minutes = parseInt(duration.split(':')[0]) * 60 + parseInt(duration.split(':')[1]);
    if (minutes < 2) {
      return 'yellow';
    } else if (minutes > 20 && minutes <= 60) {
      return 'cyan';
    } else if (minutes > 60) {
      return '#2afe00';
    } else {
      return 'inherit';
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999);

        fetchUserDetails(user.uid);
        fetchReports(user.uid, today, endOfDay);
      }
    });

    return () => unsubscribe();
  }, [refresh]);

  const fetchUserDetails = async (userId) => {
    const userDoc = await getDocs(query(collection(db, 'users'), where('uid', '==', userId)));
    if (!userDoc.empty) {
      const userData = userDoc.docs[0].data();
      setDepartment(userData.department);
    }
  };

  const fetchReports = async (agentId, startDate, endDate) => {
    const q = query(
      collection(db, 'reports'),
      where('agentId', '==', agentId),
      where('createdAt', '>=', Timestamp.fromDate(startDate)),
      where('createdAt', '<=', Timestamp.fromDate(endDate))
    );
    const querySnapshot = await getDocs(q);
    const reportsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setReports(reportsData);
  };

  const handleReportCreated = () => {
    setRefresh(prev => !prev);
  };

  const openEditModal = (report) => {
    setSelectedReport(report);
    setIsEditModalOpen(true);
  };

  const closeModals = () => {
    setIsEditModalOpen(false);
    setSelectedReport(null);
  };

  const handleEdit = async (updatedReport) => {
    if (selectedReport) {
      try {
        await updateDoc(doc(db, 'reports', selectedReport.id), updatedReport);
        setIsEditModalOpen(false);
        setSelectedReport(null);
        setRefresh(prev => !prev);
      } catch (error) {
        console.error("Error updating report: ", error);
      }
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <img src="..\images\logo.png" alt="Logo" className="logo" />
      <h1 className="agent-page-title">Agent Page</h1>
      <button
        style={{ position: 'absolute', top: 0, right: 0 }}
        onClick={() => setIsModalOpen(true)}
      >
        Create Report
      </button>
      <div className="agent-report-container">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>TSFR #</th>
              <th>Access Code</th>
              <th>Phone #</th>
              <th>Name</th>
              <th>Start Time</th>
              <th>Duration</th>
              <th>Notes</th>
              <th>Enrolled</th>
              <th>Enrolled Amount</th>
              <th>Not Enough Debt</th>
              <th>State Liability</th>
              <th>Federal Liability</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reports.length > 0 ? (
              reports.map((report, index) => (
                <tr key={index}>
                  <td>{new Date(report.createdAt.seconds * 1000).toLocaleDateString()}</td>
                  <td>{report.transfer}</td>
                  <td>{report.accessCode}</td>
                  <td>{report.phone}</td>
                  <td>{report.name}</td>
                  <td>{report.startTime}</td>
                  <td style={{ backgroundColor: getDurationColor(report.duration) }}>{report.duration}</td>
                  <td>{report.notes}</td>
                  <td>{report.enrolled ? 'Yes' : 'No'}</td>
                  <td>{report.enrolledAmount}</td>
                  <td>{report.notEnoughDebt ? 'Yes' : 'No'}</td>
                  <td>{report.stateLiability}</td>
                  <td>{report.federalLiability}</td>
                  <td>
                    <button onClick={() => openEditModal(report)}>Edit</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14">No reports found for today.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ReportModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onReportCreated={handleReportCreated}
      />
      {isEditModalOpen && (
        <EditModal
          isOpen={isEditModalOpen}
          onClose={closeModals}
          report={selectedReport}
          onSave={handleEdit}
          department={department} // Pass the department here
        />
      )}
    </div>
  );
}

export default AgentPage;